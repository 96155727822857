import React, {useEffect, useState} from 'react'
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import loadable from "@loadable/component";
import {Basic, Facebook, Twitter} from '../components/SEO';
import {List, WindowScroller} from 'react-virtualized';
import 'react-virtualized/styles.css';
import useWindowDimensions from "../hooks/useWindowDimensions";
import CategoryTitleBordered from "../components/helpers/categoryTitleBordered";
import {adDisplayDelay, delayMultiplicationFactor, setStreamData} from "../utils/articleUtil";
import {track} from "@cg-squad/ui-components";
import mixpanel from "../constants/mixpanel";
import {isMobile} from "react-device-detect";
import {subCategoryPageAds} from "../utils/adUnits";
import {metadata} from '../../config';
import { addTimeout } from '../utils/timeManager';

const PopularPosts = loadable(() => import("../components/popularPosts"));
const ArticlePreview = loadable(() => import("./articlePreview"));

const SubCategoryPage = ({path, data, pageContext, location}) => {
    const seo = {
        title: pageContext.title,
        description: `Discover all of our ${pageContext.title} articles, written by our knowledgeable team of writers.`
    }
    const url = `${metadata.url}/${pageContext.categorySlug}/${pageContext.slug}`;

    const [rowHeight, setRowHeight] = useState(279);
    const [articleHeight, setArticleHeight] = useState(250);
    const [allDatoCmsArticle, setAllDatoCmsArticle] = useState({
        totalCount: 0,
        nodes: []
    });
    const {width} = useWindowDimensions();

    const calculateHeights = () => {
        if (width <= 375) {
            setRowHeight(249);
            setArticleHeight(229)
        } else if (width <= 560) {
            setRowHeight(229);
            setArticleHeight(198)
        }
    }

    useEffect(() => {
        calculateHeights();
        setStreamData({
            category: undefined,
            subCategory: pageContext.title,
            article: undefined,
            author: undefined
        })
        track(mixpanel.MIXPANEL_PAGE_VIEW.SUB_CATEGORY);
        addTimeout(subCategoryPageAds, adDisplayDelay() * delayMultiplicationFactor(isMobile), [
            {key: 'category', value: pageContext.categorySlug},
            {key: 'subcategory', value: pageContext.slug}
        ]);
    }, []);

    useEffect(() => {
        calculateHeights();
    }, [width]);

    useEffect(() => {
        if (data.allDatoCmsArticle.nodes.find(node => node.slug.includes('agtestag'))) {
            const nonTestArticles = data.allDatoCmsArticle.nodes.filter(node => !node.slug.includes('agtestag'));
            setAllDatoCmsArticle({
                nodes: nonTestArticles,
                totalCount: nonTestArticles.length
            });
        } else {
            setAllDatoCmsArticle(data.allDatoCmsArticle);
        }
    }, [data.allDatoCmsArticle]);

    function rowRenderer({
                             index, // Index of row
                             isScrolling, // The List is currently being scrolled
                             isVisible, // This row is visible within the List (eg it is not an overscanned row)
                             key, // Unique key within array of rendered rows
                             parent, // Reference to the parent List (instance)
                             style, // Style object to be applied to row (to position it);
                             // This must be passed through to the rendered row element.
                         }) {

        return (
            <div key={key} style={style}>
                {isScrolling && !isVisible ?
                    <div style={{minHeight: articleHeight, width: "100%"}} className={"article-placeholder"}/> :
                    <ArticlePreview style={{minHeight: articleHeight}} data={allDatoCmsArticle.nodes[index]}
                                    className={"border-b-default border-gray-400"}
                                    imageClass="mb-0 w-1/3 lg:mr-4 float-right ml-2 lg:ml-0 lg:float-left mt-8 md:mt-0 max-w-320"
                                    headingClass="font-montserrat font-bold leading-snug mb-1 md:mb-4 text-lg line-clamp l-c-5"
                                    textClass={"line-clamp l-c-3 mb-0"}
                                    noFlag={true}/>}
            </div>
        );
    }

    return (
        <Layout relativePath={path?.replace('/', '')} path={location.pathname}>
            <Basic seo={seo}/>
            <Twitter seo={seo} url={url}/>
            <Facebook seo={seo} url={url}/>
            <div className="wrapper" data-datocms-noindex>
                <div className={"w-full lg:flex font-arial"}>
                    <div className={"lg:w-[calc(100%-317px)]"}>
                        <CategoryTitleBordered title={pageContext.title}/>
                        <div className="ad-container mb-2 min-h-[306px] lg:min-h-[276px]" data-datocms-noindex>
                            <div className="advertisement-text">Advertisement</div>
                            <div id="pt_subcategory_incontent1"/>
                        </div>
                        <WindowScroller>
                            {({height, isScrolling, onChildScroll, scrollTop, width}) => (
                                <List
                                    autoHeight
                                    height={height}
                                    isScrolling={isScrolling}
                                    onScroll={onChildScroll}
                                    rowCount={allDatoCmsArticle.totalCount}
                                    rowRenderer={rowRenderer}
                                    scrollTop={scrollTop}
                                    rowHeight={rowHeight}
                                    width={width}
                                    style={{width: "unset !important"}}
                                />
                            )}
                        </WindowScroller>
                    </div>
                    <PopularPosts affiliateCategory={pageContext.slug} className={"flex-1"}/>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query ArticlesSubCategoryWise($slug: String) {
        allDatoCmsArticle(filter: {category: {elemMatch: {slug: {eq: $slug}}}, website: {elemMatch: {name: {eq: "pt"}}}}, sort: {order: DESC, fields: publishedAt}) {
            nodes {
                heading
                excerpt
                excerptNode {
                  childMarkdownRemark {
                    html
                  }
                }
                slug
                author {
                    name
                    slug
                }
                category {
                    title
                    slug
                    treeParent {
                        slug
                    }
                    position
                    website {
                        name
                    }
                }
                cover {
                    url
                    gatsbyImageData(
                        layout: FULL_WIDTH,
                        placeholder: BLURRED,
                        forceBlurhash: false,
                        imgixParams: { auto: "compress", fit: "crop", w: "450", q: 40, ar: "3:2" }
                    )
                    width
                    height
                }
                publishedAt
            }
            totalCount
        }
    }
`
export default SubCategoryPage
